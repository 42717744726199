import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  CarryOutOutlined,
  AuditOutlined,
  ReadOutlined,
  ShopOutlined,
  PhoneOutlined,
  BellOutlined,
  GiftOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/user/member" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <UserOutlined />,
      label: "회원 관리",
    },
    children: [
      {
        exact: true,
        path: "/member",
        sidebar: {
          label: "일반 회원 목록",
        },
        component: loadable(() => import("../pages/user/MemberList")),
      },
      {
        exact: true,
        path: "/seller",
        sidebar: {
          label: "판매 회원 목록",
        },
        component: loadable(() => import("../pages/user/SellerList")),
      },
      {
        exact: true,
        path: "/supporter",
        sidebar: {
          label: "서포터즈 목록",
        },
        component: loadable(() => import("../pages/user/SupporterList")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/user/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/:id",
        component: loadable(() => import("../pages/user/DigitalCardList")),
      },
      {
        exact: true,
        visible: false,
        path: "/review/:id",
        component: loadable(() => import("../pages/user/ReviewList")),
      },
      {
        exact: true,
        path: "/supporter/request",
        sidebar: {
          label: "서포터즈 신청 목록",
        },
        component: loadable(() => import("../pages/user/SupporterRequestList")),
      },
      {
        exact: true,
        path: "/admin",
        sidebar: {
          label: "관리자 목록",
        },
        component: loadable(() => import("../pages/user/AdminList")),
      },
      {
        exact: true,
        path: "/level",
        sidebar: {
          label: "회원 등급 설정",
        },
        component: loadable(() => import("../pages/user/Level")),
      },
      {
        exact: true,
        visible: false,
        path: "/level/list/:id",
        component: loadable(() => import("../pages/user/level/UserList")),
      },
      {
        exact: true,
        visible: false,
        path: "/level/expect_list",
        component: loadable(() => import("../pages/user/level/ExpectList")),
      },
      {
        exact: true,
        path: "/recommend/list",
        sidebar: {
          label: "친구 추천 가입 목록",
        },
        component: loadable(() => import("../pages/user/RecommendList")),
      },
    ],
  },
  {
    exact: true,
    path: "/push",
    sidebar: {
      icon: <UserOutlined/>,
      label: "앱 푸시 관리",
    },
    children: [
      {
        exact: true,
        path: "/send",
        sidebar: {
          label: "푸시 보내기",
        },
        component: loadable(() => import("../pages/push/Send")),
      },
      {
        exact: true,
        path: "/template",
        sidebar: {
          label: "푸시 템플릿",
        },
        component: loadable(() => import("../pages/push/Template")),
      },
      {
        exact: true,
        visible: false,
        path: "/template/register",
        component: loadable(() => import("../pages/push/Template/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/template/detail/:id",
        component: loadable(() => import("../pages/push/Template/Detail")),
      },
      {
        exact: true,
        path: "/history",
        sidebar: {
          label: "발송 내역",
        },
        component: loadable(() => import("../pages/push/History")),
      },
    ]
  },
  {
    exact: true,
    path: "/product",
    sidebar: {
      icon: <ShopOutlined/>,
      label: "상품 관리",
    },
    children: [
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "카테고리 설정",
        },
        component: loadable(() => import("../pages/product/category/List")),
      },
/*      {
        exact: true,
        path: "/card",
        sidebar: {
          label: "상품 목록",
        },
        component: loadable(() => import("../pages/product/card/List")),
      },*/
      {
        exact: true,
        path: "/card/new",
        sidebar: {
          label: "상품 목록",
        },
        component: loadable(() => import("../pages/product/card/ListNew")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/register",
        component: loadable(() => import("../pages/product/card/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/detail/:id",
        component: loadable(() => import("../pages/product/card/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/stock/:id",
        component: loadable(() => import("../pages/product/card/stock/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/stock/user/:id",
        component: loadable(() => import("../pages/product/card/stock/UserList")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/stock/sale/:id",
        component: loadable(() => import("../pages/product/card/stock/SaleList")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/comment/:id",
        component: loadable(() => import("../pages/product/card/comment/List")),
      },
      {
        exact: true,
        path: "/digital/card",
        sidebar: {
          label: "디지털 카드팩 목록",
        },
        component: loadable(() => import("../pages/product/digitalCard/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/stock/:id",
        component: loadable(() => import("../pages/product/digitalCard/stock/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/stock/register/:id",
        component: loadable(() => import("../pages/product/digitalCard/stock/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/stock/modify/:id",
        component: loadable(() => import("../pages/product/digitalCard/stock/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/register",
        component: loadable(() => import("../pages/product/digitalCard/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/modify/:id",
        component: loadable(() => import("../pages/product/digitalCard/Modify")),
      },
      {
        exact: true,
        path: "/cardPack",
        sidebar: {
          label: "실물 카드팩 목록",
        },
        component: loadable(() => import("../pages/product/cardPack/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/cardPack/stock/user/:id",
        component: loadable(() => import("../pages/product/cardPack/stock/UserList")),
      },
      {
        exact: true,
        visible: false,
        path: "/cardPack/detail/:id",
        component: loadable(() => import("../pages/product/cardPack/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/cardPack/register",
        component: loadable(() => import("../pages/product/cardPack/Register")),
      },
/*      {
        exact: true,
        path: "/sale",
        sidebar: {
          label: "판매 목록",
        },
        component: loadable(() => import("../pages/product/sales/List")),
      },*/
      {
        exact: true,
        path: "/sale/Ing",
        sidebar: {
          label: "판매 중 목록",
        },
        component: loadable(() => import("../pages/product/sales/IngList")),
      },
      {
        exact: true,
        path: "/sale/Complete",
        sidebar: {
          label: "판매 완료 목록",
        },
        component: loadable(() => import("../pages/product/sales/CompleteList")),
      },
      {
        exact: true,
        visible: false,
        path: "/sale/card/detail/:id",
        component: loadable(() => import("../pages/product/sales/CardDetail")),
      },
      {
        exact: true,
        visible: false,
        path: "/sale/cardPack/detail/:id",
        component: loadable(() => import("../pages/product/sales/CardPackDetail")),
      },
      {
        exact: true,
        visible: false,
        path: "/sale/digital/card/detail/:id",
        component: loadable(() => import("../pages/product/sales/DigitalCardDetail")),
      },
    ],
  },
  {
    exact: true,
    path: "/gacha",
    sidebar: {
      icon: <ShopOutlined/>,
      label: "가챠 관리",
    },
    children: [
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "카테고리 설정(가챠)",
        },
        component: loadable(() => import("../pages/gacha/category/List")),
      },
      /*      {
              exact: true,
              path: "/card",
              sidebar: {
                label: "상품 목록",
              },
              component: loadable(() => import("../pages/product/card/List")),
            },*/
      {
        exact: true,
        path: "/card/new",
        sidebar: {
          label: "상품 목록(가챠)",
        },
        component: loadable(() => import("../pages/gacha/card/ListNew")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/register",
        component: loadable(() => import("../pages/gacha/card/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/detail/:id",
        component: loadable(() => import("../pages/gacha/card/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/stock/:id",
        component: loadable(() => import("../pages/gacha/card/stock/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/stock/user/:id",
        component: loadable(() => import("../pages/gacha/card/stock/UserList")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/stock/sale/:id",
        component: loadable(() => import("../pages/gacha/card/stock/SaleList")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/comment/:id",
        component: loadable(() => import("../pages/gacha/card/comment/List")),
      },
      {
        exact: true,
        path: "/digital/card",
        sidebar: {
          label: "상품팩 목록(가챠)",
        },
        component: loadable(() => import("../pages/gacha/digitalCard/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/stock/:id",
        component: loadable(() => import("../pages/gacha/digitalCard/stock/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/stock/register/:id",
        component: loadable(() => import("../pages/gacha/digitalCard/stock/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/stock/modify/:id",
        component: loadable(() => import("../pages/gacha/digitalCard/stock/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/register",
        component: loadable(() => import("../pages/gacha/digitalCard/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/modify/:id",
        component: loadable(() => import("../pages/gacha/digitalCard/Modify")),
      },
/*      {
        exact: true,
        path: "/sale",
        sidebar: {
          label: "판매 목록",
        },
        component: loadable(() => import("../pages/gacha/sales/List")),
      },
      {
        exact: true,
        path: "/sale/Ing",
        sidebar: {
          label: "판매 중 목록",
        },
        component: loadable(() => import("../pages/gacha/sales/IngList")),
      },
      {
        exact: true,
        path: "/sale/Complete",
        sidebar: {
          label: "판매 완료 목록",
        },
        component: loadable(() => import("../pages/gacha/sales/CompleteList")),
      },*/
      {
        exact: true,
        visible: false,
        path: "/sale/card/detail/:id",
        component: loadable(() => import("../pages/gacha/sales/CardDetail")),
      },
      {
        exact: true,
        visible: false,
        path: "/sale/cardPack/detail/:id",
        component: loadable(() => import("../pages/gacha/sales/CardPackDetail")),
      },
      {
        exact: true,
        visible: false,
        path: "/sale/digital/card/detail/:id",
        component: loadable(() => import("../pages/gacha/sales/DigitalCardDetail")),
      },
    ],
  },
  {
    exact: true,
    path: "/ticket",
    sidebar: {
      icon: <ReadOutlined />,
      label: "티켓 관리",
    },
    children: [
      {
        exact: true,
        path: "/digital/card",
        sidebar: {
          label: "티켓 카드팩 목록",
        },
        component: loadable(() => import("../pages/ticket/digitalCard/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/register",
        component: loadable(() => import("../pages/ticket/digitalCard/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/modify/:id",
        component: loadable(() => import("../pages/ticket/digitalCard/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/stock/:id",
        component: loadable(() => import("../pages/ticket/digitalCard/stock/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/stock/register/:id",
        component: loadable(() => import("../pages/ticket/digitalCard/stock/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/digital/card/stock/modify/:id",
        component: loadable(() => import("../pages/ticket/digitalCard/stock/Modify")),
      },
      {
        exact: true,
        path: "/pay",
        sidebar: {
          label: "티켓 결제 목록",
        },
        component: loadable(() => import("../pages/ticket/PayList")),
      },
      {
        exact: true,
        visible: false,
        path: "/pay/detail/:id",
        component: loadable(() => import("../pages/ticket/PayDetail")),
      },
      {
        exact: true,
        path: "/user",
        sidebar: {
          label: "티켓 소유 목록",
        },
        component: loadable(() => import("../pages/ticket/User")),
      },
      {
        exact: true,
        visible: false,
        path: "/user/detail/:id",
        component: loadable(() => import("../pages/ticket/UserDetail")),
      },
    ],
  },
  {
    exact: true,
    path: "/stock",
    sidebar: {
      icon: <ReadOutlined />,
      label: "재고 관리",
    },
    children: [
      {
        exact: true,
        path: "/admin",
        sidebar: {
          label: "박스 재고 목록",
        },
        component: loadable(() => import("../pages/stock/admin/List")),
      },
      {
        exact: true,
        path: "/admin/gacha",
        sidebar: {
          label: "굿즈 재고 목록",
        },
        component: loadable(() => import("../pages/stock/admin/GachaList")),
      },
      {
        exact: true,
        path: "/admin/card",
        sidebar: {
          label: "카드 재고 목록",
        },
        component: loadable(() => import("../pages/stock/admin/CardList")),
      },
      {
        exact: true,
        visible: false,
        path: "/admin/register",
        component: loadable(() => import("../pages/stock/admin/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/admin/modify/:id",
        component: loadable(() => import("../pages/stock/admin/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/admin/stock/:id/:status",
        component: loadable(() => import("../pages/stock/admin/stock/List")),
      },
      {
        exact: true,
        path: "/psa",
        sidebar: {
          label: "PSA 포인트 변환 목록",
        },
        component: loadable(() => import("../pages/stock/psa/List")),
      },
    ]
  },
  {
    exact: true,
    path: "/play",
    sidebar: {
      icon: <ReadOutlined />,
      label: "티프 플레이 관리",
    },
    children: [
      {
        exact: true,
        path: "/exchange",
        sidebar: {
          label: "교환 센터 목록",
        },
        component: loadable(() => import("../pages/play/exchange/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/exchange/detail/:id",
        component: loadable(() => import("../pages/play/exchange/Detail")),
      },
      {
        exact: true,
        path: "/gacha",
        sidebar: {
          label: "가챠 센터 목록",
        },
        component: loadable(() => import("../pages/play/gacha/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/gacha/detail/:id",
        component: loadable(() => import("../pages/play/gacha/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/gacha/card/stock/:id",
        component: loadable(() => import("../pages/play/gacha/stock/List")),
      },
      {
        exact: true,
        path: "/shop",
        sidebar: {
          label: "SHOP 상품 목록",
        },
        component: loadable(() => import("../pages/play/shop/ListNew")),
      },
      {
        exact: true,
        visible: false,
        path: "/shop/register",
        component: loadable(() => import("../pages/play/shop/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/shop/detail/:id",
        component: loadable(() => import("../pages/play/shop/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/shop/stock/:id",
        component: loadable(() => import("../pages/play/shop/stock/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/shop/stock/user/:id",
        component: loadable(() => import("../pages/play/shop/stock/UserList")),
      },
      {
        exact: true,
        visible: false,
        path: "/shop/stock/sale/:id",
        component: loadable(() => import("../pages/play/shop/stock/SaleList")),
      },
      {
        exact: true,
        path: "/gacha/payment",
        sidebar: {
          label: "가챠 센터 결제 목록",
        },
        component: loadable(() => import("../pages/play/payment/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/gacha/payment/detail/:id",
        component: loadable(() => import("../pages/play/payment/Detail")),
      },
    ]
  },
  {
    exact: true,
    path: "/pay",
    sidebar: {
      icon: <DollarCircleOutlined />,
      label: "결제/정산 관리",
    },
    children: [
      {
        exact: true,
        path: "/fee",
        sidebar: {
          label: "수수료 및 배송비 목록",
        },
        component: loadable(() => import("../pages/pay/FeeList")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "결제 목록",
        },
        component: loadable(() => import("../pages/pay/PayList")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/pay/PayDetail")),
      },
/*      {
        exact: true,
        path: "/payment",
        sidebar: {
          label: "정산 예정 목록",
        },
        component: loadable(() => import("../pages/payment/List")),
      },*/
      {
        exact: true,
        path: "/calculate/complete",
        sidebar: {
          label: "정산 목록",
        },
        component: loadable(() => import("../pages/calculate/CompleteList")),
      },
      {
        exact: true,
        path: "/money",
        sidebar: {
          label: "티프 머니 출금 목록",
        },
        component: loadable(() => import("../pages/money/List")),
      },
      {
        exact: true,
        path: "/charge",
        sidebar: {
          label: "코인 충전 목록",
        },
        component: loadable(() => import("../pages/pay/ChargeList")),
      },
    ],
  },
  {
    exact: true,
    path: "/community",
    sidebar: {
      icon: <ReadOutlined />,
      label: "게시글 관리",
    },
    children: [
      {
        exact: true,
        path: "/member",
        sidebar: {
          label: "일반 게시글 목록",
        },
        component: loadable(() => import("../pages/community/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/member/detail/:id",
        component: loadable(() => import("../pages/community/Detail")),
      },
      {
        exact: true,
        path: "/supporter",
        sidebar: {
          label: "서포터즈 게시글 목록",
        },
        component: loadable(() => import("../pages/community/SupportersList")),
      },
      {
        exact: true,
        visible: false,
        path: "/supporter/detail/:id",
        component: loadable(() => import("../pages/community/SupportersDetail")),
      },
    ],
  },
  {
    exact: true,
    path: "/request",
    sidebar: {
      icon: <GiftOutlined />,
      label: "교환/제작 요청 관리",
    },
    children: [
      {
        exact: true,
        path: "/exchange/card",
        sidebar: {
          label: "디지털 카드 교환 요청 목록",
        },
        component: loadable(() => import("../pages/request/exchange/digitalCard/List")),
      },
      {
        exact: true,
        path: "/exchange/card/visit",
        sidebar: {
          label: "카드 현장 수령 요청 목록",
        },
        component: loadable(() => import("../pages/request/exchange/digitalCard/VisitList")),
      },
      {
        exact: true,
        path: "/exchange/card/visit/holiday",
        sidebar: {
          label: "카드 현장 수령 휴무일 관리",
        },
        component: loadable(() => import("../pages/request/exchange/digitalCard/VisitHoliday")),
      },
      {
        exact: true,
        visible: false,
        path: "/exchange/card/detail/:id",
        component: loadable(() => import("../pages/request/exchange/digitalCard/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/exchange/card/visit/detail/:id",
        component: loadable(() => import("../pages/request/exchange/digitalCard/VisitDetail")),
      },
      {
        exact: true,
        path: "/make/card",
        sidebar: {
          label: "커스텀 카드 제작 요청 목록",
        },
        component: loadable(() => import("../pages/request/make/card/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/make/card/detail/:id",
        component: loadable(() => import("../pages/request/make/card/Detail")),
      },
      {
        exact: true,
        path: "/new",
        sidebar: {
          label: "신규 카드 등록 요청 목록",
        },
        component: loadable(() => import("../pages/request/new/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/new/detail/:id",
        component: loadable(() => import("../pages/request/new/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/psa",
    sidebar: {
      icon: <GiftOutlined/>,
      label: "그레이딩 요청 관리",
    },
    children: [
      {
        exact: true,
        path: "/grading",
        sidebar: {
          label: "그레이딩 요청 목록",
        },
        component: loadable(() => import("../pages/psa/List")),
      },
      {
        exact: true,
        path: "/grading/exchange",
        sidebar: {
          label: "그레이딩 배송 요청 목록",
        },
        component: loadable(() => import("../pages/psa/ExchangeList")),
      },
      {
        exact: true,
        path: "/grading/register",
        sidebar: {
          label: "그레이딩 등록 관리",
        },
        component: loadable(() => import("../pages/psa/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/grading/detail/:id",
        component: loadable(() => import("../pages/psa/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/grading/exchange/detail/:id",
        component: loadable(() => import("../pages/psa/ExchangeDetail")),
      },
    ]
  },
  {
    exact: true,
    path: "/coupon",
    sidebar: {
      icon: <AuditOutlined />,
      label: "쿠폰/TIF COIN 관리",
    },
    children: [
      {
        exact: true,
        path: "/index",
        sidebar: {
          label: "쿠폰 목록",
        },
        component: loadable(() => import("../pages/coupon/List")),
      },
      {
        exact: true,
        path: "/point",
        sidebar: {
          label: "TIF COIN 자동지급 설정",
        },
        component: loadable(() => import("../pages/coupon/PointList")),
      },
      {
        exact: true,
        path: "/history/coupon",
        sidebar: {
          label: "쿠폰 지급 내역",
        },
        component: loadable(() => import("../pages/coupon/CouponHistoryList")),
      },
      {
        exact: true,
        path: "/history/point",
        sidebar: {
          label: "TIF COIN 지급 내역",
        },
        component: loadable(() => import("../pages/coupon/PointHistoryList")),
      },
      {
        exact: true,
        path: "/history/payback",
        sidebar: {
          label: "TIF COIN 변환 내역",
        },
        component: loadable(() => import("../pages/coupon/PaybackHistoryList")),
      },
    ],
  },
  {
    exact: true,
    path: "/attendance",
    sidebar: {
      icon: <CarryOutOutlined />,
      label: "출석 관리",
    },
    children: [
      {
        exact: true,
        path: "/popup",
        sidebar: {
          label: "출석 팝업 설정",
        },
        component: loadable(() => import("../pages/attendance/popup/Index")),
      },
      {
        exact: true,
        path: "/point",
        sidebar: {
          label: "월별 코인 수집 지급 설정",
        },
        component: loadable(() => import("../pages/attendance/MonthList")),
      },
      {
        exact: true,
        path: "/coin",
        sidebar: {
          label: "코인 뽑기 지급 설정",
        },
        component: loadable(() => import("../pages/attendance/coinList")),
      },
    ],
  },
  {
    exact: true,
    path: "/site",
    sidebar: {
      icon: <SettingOutlined />,
      label: "사이트 관리",
    },
    children: [
      {
        exact: true,
        path: "/promotion",
        sidebar: {
          label: "프로모션 관리",
        },
        component: loadable(() => import("../pages/site/promotion/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/promotion/register",
        component: loadable(() => import("../pages/site/promotion/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/promotion/modify/:id",
        component: loadable(() => import("../pages/site/promotion/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/promotion/enter/:id",
        component: loadable(() => import("../pages/site/promotion/EnterList")),
      },
      {
        exact: true,
        visible: false,
        path: "/promotion/comments/:id",
        component: loadable(() => import("../pages/site/promotion/CommentsList")),
      },
      {
        exact: true,
        path: "/home/main/banner",
        sidebar: {
          label: "홈화면 메인 배너 관리",
        },
        component: loadable(() => import("../pages/site/mainBanner/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/home/main/banner/register",
        component: loadable(() => import("../pages/site/mainBanner/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/home/main/banner/detail/:id",
        component: loadable(() => import("../pages/site/mainBanner/Modify")),
      },
      {
        exact: true,
        path: "/home/play/banner",
        sidebar: {
          label: "PLAY화면 메인 배너 관리",
        },
        component: loadable(() => import("../pages/site/playBanner/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/home/play/banner/register",
        component: loadable(() => import("../pages/site/playBanner/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/home/play/banner/detail/:id",
        component: loadable(() => import("../pages/site/playBanner/Modify")),
      },
      {
        exact: true,
        path: "/home/sub/banner",
        sidebar: {
          label: "홈화면 서브 배너 관리",
        },
        component: loadable(() => import("../pages/site/subBanner/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/home/sub/banner/register",
        component: loadable(() => import("../pages/site/subBanner/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/home/sub/banner/detail/:id",
        component: loadable(() => import("../pages/site/subBanner/Modify")),
      },
      {
        exact: true,
        path: "/card/banner",
        sidebar: {
          label: "카드팩샵 배너 관리",
        },
        component: loadable(() => import("../pages/site/cardBanner/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/banner/register",
        component: loadable(() => import("../pages/site/cardBanner/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/card/banner/detail/:id",
        component: loadable(() => import("../pages/site/cardBanner/Modify")),
      },
      {
        exact: true,
        path: "/pick/banner",
        sidebar: {
          label: "Pick 배너 관리",
        },
        component: loadable(() => import("../pages/site/pickBanner/Index")),
      },
      {
        exact: true,
        path: "/term",
        sidebar: {
          label: "약관 및 이용안내 관리",
        },
        component: loadable(() => import("../pages/site/term/List")),
      },
      {
        exact: true,
        path: "/badge",
        sidebar: {
          label: "배지 관리",
        },
        component: loadable(() => import("../pages/site/badge/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/badge/detail/:id",
        component: loadable(() => import("../pages/site/badge/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/badge/register",
        component: loadable(() => import("../pages/site/badge/Register")),
      },
      {
        exact: true,
        path: "/supporter/badge",
        sidebar: {
          label: "서포터즈 배지 관리",
        },
        component: loadable(() => import("../pages/site/badge/supporter/Index")),
      },
      {
        exact: true,
        path: "/api",
        sidebar: {
          label: "api 관리",
        },
        component: loadable(() => import("../pages/site/api/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/cs",
    sidebar: {
      icon: <PhoneOutlined />,
      label: "고객센터",
    },
    children: [
      {
        exact: true,
        path: "/notice",
        sidebar: {
          label: "공지사항 목록",
        },
        component: loadable(() => import("../pages/cs/notice/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/detail/:id",
        component: loadable(() => import("../pages/cs/notice/Modify")),
      },
      {
        exact: true,
        visible: false,
        path: "/notice/register",
        component: loadable(() => import("../pages/cs/notice/Register")),
      },
      {
        exact: true,
        path: "/report/card",
        sidebar: {
          label: "카드 신고 목록",
        },
        component: loadable(() => import("../pages/cs/report/CardList")),
      },
      {
        exact: true,
        visible: false,
        path: "/report/card/detail/:id",
        component: loadable(() => import("../pages/cs/report/CardDetail")),
      },
      {
        exact: true,
        path: "/report/comment",
        sidebar: {
          label: "댓글/코멘트 신고 목록",
        },
        component: loadable(() => import("../pages/cs/report/CommentList")),
      },
      {
        exact: true,
        path: "/report/review",
        sidebar: {
          label: "리뷰 신고 목록",
        },
        component: loadable(() => import("../pages/cs/report/ReviewList")),
      },
      {
        exact: true,
        path: "/report/community",
        sidebar: {
          label: "게시글 신고 목록",
        },
        component: loadable(() => import("../pages/cs/report/CommunityList")),
      },
      {
        exact: true,
        visible: false,
        path: "/report/community/detail/:id",
        component: loadable(() => import("../pages/cs/report/CommunityDetail")),
      },
      {
        exact: true,
        path: "/report/profile",
        sidebar: {
          label: "프로필 신고 목록",
        },
        component: loadable(() => import("../pages/cs/report/ProfileList")),
      },
      {
        exact: true,
        path: "/qna",
        sidebar: {
          label: "1:1 문의 목록",
        },
        component: loadable(() => import("../pages/cs/qna/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/qna/detail/:id",
        component: loadable(() => import("../pages/cs/qna/Detail")),
      },
      {
        exact: true,
        path: "/faq",
        sidebar: {
          label: "FAQ 목록",
        },
        component: loadable(() => import("../pages/cs/faq/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/category",
        component: loadable(() => import("../pages/cs/faq/category/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/register",
        component: loadable(() => import("../pages/cs/faq/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/faq/modify/:id",
        component: loadable(() => import("../pages/cs/faq/Modify")),
      },
    ],
  },
];


//로그인 시에만 이용가능 페이지
export const privateRoutesLevel99 = [
  {
    exact: true,
    path: "/request",
    sidebar: {
      icon: <GiftOutlined />,
      label: "교환/제작 요청 관리",
    },
    children: [
      {
        exact: true,
        path: "/exchange/card/visit",
        sidebar: {
          label: "카드 현장 수령 요청 목록",
        },
        component: loadable(() => import("../pages/request/exchange/digitalCard/VisitList")),
      },
      {
        exact: true,
        visible: false,
        path: "/exchange/card/detail/:id",
        component: loadable(() => import("../pages/request/exchange/digitalCard/Detail")),
      },
      {
        exact: true,
        path: "/exchange/card/visit/holiday",
        sidebar: {
          label: "카드 현장 수령 휴무일 관리",
        },
        component: loadable(() => import("../pages/request/exchange/digitalCard/VisitHoliday")),
      },
    ],
  },
  {
    exact: true,
    path: "/cs",
    sidebar: {
      icon: <PhoneOutlined/>,
      label: "고객센터",
    },
    children: [
      {
        exact: true,
        path: "/qna",
        sidebar: {
          label: "1:1 문의 목록",
        },
        component: loadable(() => import("../pages/cs/qna/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/qna/detail/:id",
        component: loadable(() => import("../pages/cs/qna/Detail")),
      },
    ]
  }
];
